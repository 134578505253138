import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import queryString from "query-string";
//import fakeAuth from "fake-auth";
import { useUser, createUser, updateUser } from "./db";
import { history } from "./router";
import PageLoader from "./../components/PageLoader";
import { getFriendlyPlanId } from "./prices";
import analytics from "./analytics";
import userbase, { UserResult } from 'userbase-js';
import { CustomError } from "./util";

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = true;

// Whether to connect analytics session to user.uid
const ANALYTICS_IDENTIFY = true;

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  // Connect analytics session to user
  useIdentifyUser(finalUser);

  // Handle response from authentication functions
  const handleAuth = async (user) => {
    // Create the user in the database
    // fake-auth doesn't indicate if they are new so we attempt to create user every time
    //await createUser(user.userId, { email: user.username });

    // Update user in state
    const formattedUser = formatUser(user);
    setUser(formattedUser);
    return formattedUser;
  };

  const handleAuthError = async (user, error) => {
    //check error message
    var errorMessage = error;

    throw new CustomError("", "Invalid Username or Password");

    return errorMessage;
  }

  const signup = (username, password) => {    
    // return fakeAuth
    //   .signup(email, password)
    //   .then((response) => handleAuth(response.user));
    return userbase.signUp({
      username: username,
      password: password
    }).then((user) => {
      // user account created
;      handleAuth(user)
    }).catch((e) => handleAuthError(user, e));
  };

  const signin = (username, password) => {
    return userbase.signIn({
      username: username,
      password: password
    }).then((user) => {
      // user logged in
      handleAuth(user)
    }).catch((e) => 
      //console.error(e),
      handleAuthError(user, e)
      );
    // return fakeAuth
    //   .signin(email, password)
    //   .then((response) => handleAuth(response.user));
  };

   const signinWithProvider = (name) => {
  //   return fakeAuth
  //     .signinWithProvider(name)
  //     .then((response) => handleAuth(response.user));
   };

  const signout = () => {
    return userbase.signOut().then(() => {
      setUser(false);
      // user logged out
    }).catch((e) => console.error(e))
    //return fakeAuth.signout();
  };

  const sendPasswordResetEmail = (email) => {
    return userbase.forgotPassword({
      username: email
    }).then(() => {
      // email with temporary password sent
    }).catch((e) => console.error(e))
    //return fakeAuth.sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (password, code) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").

    // Get code from query string object
    const resetCode = code || getFromQueryString("code");
    //return fakeAuth.confirmPasswordReset(password, resetCode);
  };

  const updateEmail = (email) => {
    // return fakeAuth.updateEmail(email).then((rawUser) => {
    //   setUser(rawUser);
    // });
  };

  const updatePassword = (password) => {
    // return fakeAuth.updatePassword(password);
  };

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, name, picture } = data;

    // Update auth email
    if (email) {
      //await fakeAuth.updateEmail(email);
    }

    // Update auth profile fields
    if (name || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (picture) fields.picture = picture;
      //await fakeAuth.updateProfile(fields);
    }

    // Persist all data to the database
    await updateUser(user.userId, data);

    // Update user in state
    //const currentUser = await fakeAuth.getCurrentUser();
    //setUser(currentUser);
  };

  useEffect(() => {
    //init userbase
    userbase
    .init({ appId: process.env.REACT_APP_USERBASE_APP_ID })
    .then((session) => {
      // SDK initialized successfully
    
      if (session.user) {
        // there is a valid active session
        //console.log(session.user.username)
        const user = formatUser(session.user);
        setUser(user);
      }
      else{
        setUser(false);
      }
    }).catch((e) => console.error(e));

    // Subscribe to user on mount
    //const unsubscribe = fakeAuth.onChange(async (response) => {
      //if (response.user) {
        //setUser(response.user);
      //} else {
        //setUser(false);
      //}
   //});

    // Unsubscribe on cleanup
    //return () => unsubscribe();
  }, []);

  return {
    user,//: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
  };
}

// Format user object
// If there are extra fields you want from the original user
// object then you'd add those here.
const formatUser = (user) => {
  return {
    uid: user.userId,
    email: user.username,
    // // Create an array containing the user's providers (password, google, etc).
    // providers: user.providerData.map(({ providerId }) => {
    //   // Get the name for this providerId
    //   return providers.find((p) => p.id === providerId).name;
    // }),
  };
};

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const userDbQuery = useUser(MERGE_DB_USER && user && user.userId);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    let finalUser = {
      uid: user.uid,
      email: user.email,
      name: user.name,
      picture: user.picture,
    };

    // Include an array of user's auth providers, such as ["password", "google", etc]
    // Components can read this to prompt user to re-auth with the correct provider
    finalUser.providers = [user.provider];

    // If merging user data from database is enabled ...
    if (MERGE_DB_USER) {
      switch (userDbQuery.status) {
        case "idle":
          // Return null user until we have db data to merge
          return null;
        case "loading":
          return null;
        case "error":
          // Log query error to console
          console.error(userDbQuery.error);
          return null;
        case "success":
          // If user data doesn't exist we assume this means user just signed up and the createUser
          // function just hasn't completed. We return null to indicate a loading state.
          if (userDbQuery.data === null) return null;

          // Merge user data from database into finalUser object
          Object.assign(finalUser, userDbQuery.data);

          // Get values we need for setting up some custom fields below
          const { stripePriceId, stripeSubscriptionStatus } = userDbQuery.data;

          // Add planId field (such as "basic", "premium", etc) based on stripePriceId
          if (stripePriceId) {
            finalUser.planId = getFriendlyPlanId(stripePriceId);
          }

          // Add planIsActive field and set to true if subscription status is "active" or "trialing"
          finalUser.planIsActive = ["active", "trialing"].includes(
            stripeSubscriptionStatus
          );

        // no default
      }
    }

    return finalUser;
  }, [user, userDbQuery]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false) {
        history.replace("/auth/signin");
      }
    }, [auth]);

    // Show loading indicator
    // We're either loading (user is null) or we're about to redirect (user is false)
    if (!auth.user) {
      return <PageLoader />;
    }

    // Render component now that we have user
    return <Component {...props} />;
  };
};

// Connect analytics session to current user.uid
function useIdentifyUser(user) {
  useEffect(() => {
    if (ANALYTICS_IDENTIFY && user) {
      analytics.identify(user.userId);
    }
  }, [user]);
}

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
